<template>
  <div>
    <ValidationObserver tag="span" v-slot="{errors}">
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel v-for="(d, index) in diligentEffortValue" :key="index">
          <v-expansion-panel-header>
            <h5 :class="{'hasError': panelHasError(errors, index)}">Diligent Effort Confirmation #{{index + 1}}</h5>
            <v-spacer />
            
            <v-btn color="#fdb714" type="button" icon x-small height="30" width="30" class="ml-1 mr-2"
              style="flex: none;"
              v-show="showToggleDE(d)"
              @click.stop="toggleSaveDE(d)">
                <div v-show="deIsSaved(d)" title="Remove from Saved Templates">
                  <i class="fas fa-star" style="font-size: 20px;"></i>
                </div>
                <div v-show="!deIsSaved(d)" title="Add to Saved Templates">
                  <i class="fal fa-star" style="font-size: 20px;"></i>
                </div>
            </v-btn>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DiligentEffortV1 
              v-bind="{
                value: d,
                index: index,
                disabled: disabled,
                channel: channel,
                otherDEs: diligentEffortValue.filter((o,i) => (i != index)),
                insuranceCarrierOptions: insuranceCarrierOptions,
                mode: mode
              }"
              @change="updateDE($event, index)" 
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </ValidationObserver>
  </div>
</template>

<script>
  import DiligentEffortV1 from '@/components/form/DiligentEffortV1.vue'

  export default {
    name: 'DiligentEffortListV1',
    components: {
      DiligentEffortV1
    },
    data() {
      return {
        diligentEffortValue: [],
        panel: [],
        savedDEs: [],
        insuranceCarrierOptions: []
      }
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      errors: {
        type: Array,
        default: () => []
      },
      disabled: Boolean,
      channel: String,
      rejectionsRequired: {
        type: Number,
        default: 1
      },
      state: {
        type: String,
        default: null
      },
      initialization: {
        type: Boolean,
        default: false
      },
      mode: String
    },
    emits: [
      'change'
    ],
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (newVal && newVal.length) {
            this.diligentEffortValue = newVal;
          } else {
            this.diligentEffortValue = [];
          }
        }
      },
      initialization: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.initialize()
          }
        }
      }
    },
    computed: {

    },
    methods: {
      showToggleDE(d) {
        let show = false;
        if (
          d && d.Company  &&
          d.ContactName &&
          d.ContactPhone
        ) {
          show = true;
        }
        return show;
      },
      deIsSaved(d) {
        let saved = false;
        if (
          this.savedDEs &&
          this.savedDEs.length &&
          d.Company &&
          this.savedDEs.find(i => i.Company == d.Company)
        ) {
          saved = true;
        }
        return saved;
      },
      toggleSaveDE(d) {
        if (this.deIsSaved(d)) {
          //alert('removing');
          this.$store.dispatch('removeSavedDiligentEffortV1', d)
        } else {
          //alert('adding');
          const v = { ...d }
          delete v.ContactedDate;
          delete v.DeclineReason;
          this.$store.dispatch('addSavedDiligentEffortV1', v)
        }
        this.$forceUpdate();
      },
      updateDE($event, index) {
        this.diligentEffortValue[index] = $event;
        this.$forceUpdate();
        this.$emit("change", this.diligentEffortValue);
      },
      openAll() {
        for (let i = 0; i < this.diligentEffortValue.length; i++) {
          this.panel.push(i);
        }
      },
      openFirst() {
        if (this.rejectionsRequired > 0) {
          this.panel = [0];
        }
      },
      panelHasError(errors, index) {
        if (!errors) return false;

        let e = false;
        const keys = Object.keys(errors);
        keys.forEach((k) => {
          if (errors[k].length > 0) {
            const i = k.split('_')[1];
            if (i == index) e = true;
          }
        });
        return e;
      },
      loadSavedDEs() {
        if (this.savedDEs && this.savedDEs.length > 0) {
          this.diligentEffortValue.forEach((item, index) => {
            if (this.savedDEs.length > index && this.savedDEs[index]) {

              const saved = { ...this.savedDEs[index] };

              const v = {
                Company: saved.Company,
                ContactName: saved.ContactName,
                ContactPhone: saved.ContactPhone,
                ContactEmail: saved.ContactEmail,
                ContactWebsite: saved.ContactWebsite
              };
              this.diligentEffortValue[index] = v;
            }
          })
        }
        this.$forceUpdate();
      },
      initialize() {
        if (this.diligentEffortValue.length < this.rejectionsRequired) {
          for (let i = 0; i < this.rejectionsRequired; i++) {
            if (!this.diligentEffortValue[i]) this.diligentEffortValue.push({});
          }
        }

        this.$store.watch(() => {
          const newDEs = this.savedDEs.length != this.$store.getters.getSavedDiligentEffortsV1;
          this.savedDEs = this.$store.getters.getSavedDiligentEffortsV1;
          if (newDEs) this.$forceUpdate();

          if (!this.diligentEffortValue.find(i => i.Company)) {
            this.loadSavedDEs();
          }

        }, () => { },
          {
            immediate: true,
            deep: true
          });



        this.openAll();
        setTimeout(() => {
          this.openFirst();
        }, 100)

        this.$emit('change', this.diligentEffortValue)
      }
    }
  }
</script>

<style scoped lang="scss">
@import '@/style.scss';

  .hasError {
    color: $tertiary-red;
    font-weight: bold;
  }
</style>
