var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded)?_c('div',[(_vm.requirements.length)?_c('div',{staticClass:"requirements"},_vm._l((_vm.requirements),function(item,index){return _c('div',{key:index},[(item.Type == 'FileUpload')?[_c('DynamicFormControl',{attrs:{"control":{
                type: 'FileUpload', 
                name: item.name,
                value: _vm.requirementsValue[item.Name] ? _vm.requirementsValue[item.Name].Files || [] : [],
                config: {
                    label: item.Label,
                    rules: _vm.fileRules,
                    col: 12,
                    disabled: _vm.disabled,
                    max: item.Max || 1,
                    description: item.Text,
                    template: item.Template,
                    product: item.Product,
                    shouldCreateTempFileId: item.IsV1OrV2
                }
            }},on:{"change":function($event){return _vm.itemChange($event, item)}}})]:(item.Type == 'Attestation')?[_c('DynamicFormControl',{attrs:{"control":{
                type: 'Attestation', 
                name: item.Name,
                value: _vm.requirementsValue[item.Name] ? _vm.requirementsValue[item.Name].Value || null : null,
                config: {
                    label: item.Text,
                    rules: _vm.attestationRules,
                    col: 12,
                    disabled: _vm.disabled,
                }
            }},on:{"change":function($event){return _vm.itemChange($event, item)}}})]:_vm._e()],2)}),0):[_c('br'),_c('v-alert',{staticClass:"text-left",attrs:{"type":"info","dense":""}},[_vm._v(" There are no requirements at this time. ")])]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }