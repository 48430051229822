<template>
  <div>
    <div v-if="!showDEs" class="text-center">
      <Loading />
    </div>
    <div v-if="showDEs">
      <label v-if="rejectionsRequired > 0 && defaultDEWording === true" class="text-bold">
        Have you tried to place this policy with an admitted market?<br>
      </label>
      
      <label v-if="rejectionsRequired > 0 && defaultDEWording === false" class="text-bold">
        Please download the state packet, complete it according to the provided instructions, and upload it once finished.<br>
      </label>   

      <label v-if="rejectionsRequired == 0 && defaultDEWording === true" class="text-bold">
        No Diligent Effort Confirmations are required for Risk Locations in the State of {{state}}.
      </label>
      <hr class="my-5">
      <v-radio-group v-model="diligentEffortAndRequirementsObj.Active">
        <div v-if="rejectionsRequired > 0 && defaultDEWording === true">
          <p color="theme-light">Option 1</p>
          <v-radio class="mb-6" color="#002B49" theme="light" name="diligentEffortAndRequirementsObj.Active" value="DiligentEffortList">
            <template v-slot:label>
              <strong>Complete via the form below</strong>
            </template>
          </v-radio>
          <DiligentEffortListV1 @change="diligentEffortChange"
            :initialization="initialization" 
            :rejectionsRequired="rejectionsRequired"
            :value="diligentEffortAndRequirementsObj.DiligentEffortList"
            :errors="errors"
            :disabled="disabled"
            :channel="channel"
            :module="module"
            :state="state"
            :mode="diligentEffortMode"
          />
          <hr class="mt-7">
          <p class="mt-5">Option 2</p>
        </div>
        <v-radio v-if="requirementsRequired" class="my-1" color="#002B49" name="diligentEffortAndRequirementsObj.Active" value="Requirements">
          <template v-slot:label>
            <strong>Download state packet</strong>
          </template>
        </v-radio>
        <Requirements @requirements="checkForRequirementsRequired"
          @change="requirementsChange"
          :value="diligentEffortAndRequirementsObj.Requirements"
          :errors="errors"
          :disabled="disabled"
          :channel="channel"
          :module="module"
          :mode="requirementsMode"
        />
      </v-radio-group>
          
      <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
  </div>
</template>

<script>
  import DiligentEffortListV1 from "@/components/form/DiligentEffortListV1.vue"
  import Requirements from "@/components/form/Requirements.vue"
  import { SysService } from "@/api/SysService"

  export default {
    name: "DiligentEffortAndRequirements",
    components: {
      DiligentEffortListV1,
      Requirements
    },
    data() {
      return {
        rejectionsRequired: 1,
        defaultDEWording: true,
        requirementsRequired: false,
        state: null,
        showDEs: false,
        initialization: false,
        requirementsMode: 'MANDATORY',
        diligentEffortMode: "OPTIONAL",
        diligentEffortAndRequirementsObj: {
          Active: "Requirements",
          DiligentEffortList: [],
          Requirements: {}
        }
      }
    },
    props: {
      value: null,
      errors: {
        type: Array,
        default: () => []
      },
      disabled: Boolean,
      channel: String,
      module: String
    },
    emits: [
      'change'
    ],
    created() {
      const risk = this.$store.getters.getControlValue('additionalForm', 'Risk')
      if (risk) {
        this.state = risk.State;
        SysService.diligentEffort({ product: this.channel })
          .then((data) => {
            const s = data.find(o => o.StateCode == this.state);
            if (s) {
              this.rejectionsRequired = s.RequiredAuthorizedMarketRejections;
              this.defaultDEWording = s.ShowDiligentEfforts;
            }
            this.clearDiligentEffortWhenNotAvailable(this.rejectionsRequired);
            this.showDEs = true;
            this.initialization = true;
          })
      } else {
        this.showDEs = true;
        this.initialization = true;
      }

      var diligentEffortAndRequirementsFormData = this.$store.getters.getFormDataValue('additionalForm', 'DiligentEffortAndRequirements')?.value;

      if (diligentEffortAndRequirementsFormData) {
        this.diligentEffortAndRequirementsObj = diligentEffortAndRequirementsFormData;
      }
    },
    methods: {
      diligentEffortChange(value) {
        this.diligentEffortAndRequirementsObj.DiligentEffortList = value;
        this.$emit('change', this.diligentEffortAndRequirementsObj);
      },
      requirementsChange(value) {
        this.diligentEffortAndRequirementsObj.Requirements = value;
        this.$emit('change', this.diligentEffortAndRequirementsObj);
      },
      checkForRequirementsRequired(value) {
        if (value) {
          this.requirementsRequired = true;
        } else {
          this.diligentEffortAndRequirementsObj.Requirements = null;
        }
      },
      clearDiligentEffortWhenNotAvailable(rejections) {
        if (rejections === 0) {
          this.diligentEffortAndRequirementsObj.DiligentEffortList = null;
          if (this.requirementsRequired && this.diligentEffortAndRequirementsObj !== null) {
            this.diligentEffortAndRequirementsObj.Active = "Requirements"
          }
        }
      }
    },
    watch: {
      diligentEffortAndRequirementsObj: {
        deep: true,
        handler(newVal) {
          if (newVal?.Active === 'Requirements') {
            this.requirementsMode = 'MANDATORY';
            this.diligentEffortMode = 'OPTIONAL';
          }
          if (newVal?.Active === 'DiligentEffortList') {
            this.requirementsMode = 'OPTIONAL';
            this.diligentEffortMode = 'MANDATORY';
          }
          this.$emit('change', newVal);
        }
      },
      value: {
        immediate: true,
        deep: true,
        handler(newVal) {
          if (newVal) {
            this.diligentEffortAndRequirementsObj = newVal;
          }

          if (!this.diligentEffortAndRequirementsObj?.Active) {
            this.diligentEffortAndRequirementsObj.Active = "Requirements"
          }
        }
      }
    }
  }
</script>