<template>
  <div>
    <!-- <div v-if="efileTypes.length == 0" class="text-center">
      <br>
      <Loading />
    </div> -->
    <div v-if="loaded">
      <div v-if="requirements.length" class="requirements">
        <div v-for="(item, index) in requirements" :key="index">

          <template v-if="item.Type == 'FileUpload'">
            <DynamicFormControl 
              :control="{
                  type: 'FileUpload', 
                  name: item.name,
                  value: requirementsValue[item.Name] ? requirementsValue[item.Name].Files || [] : [],
                  config: {
                      label: item.Label,
                      rules: fileRules,
                      col: 12,
                      disabled: disabled,
                      max: item.Max || 1,
                      description: item.Text,
                      template: item.Template,
                      product: item.Product,
                      shouldCreateTempFileId: item.IsV1OrV2
                  }
              }"
              @change="itemChange($event, item)" 
            />
          </template>
          <template v-else-if="item.Type == 'Attestation'">
            <DynamicFormControl 
              :control="{
                  type: 'Attestation', 
                  name: item.Name,
                  value: requirementsValue[item.Name] ? requirementsValue[item.Name].Value || null : null,
                  config: {
                      label: item.Text,
                      rules: attestationRules,
                      col: 12,
                      disabled: disabled,
                  }
              }"
              @change="itemChange($event, item)" 
            />
          </template>
          
          
        </div>
      </div>

      <template v-else>
        <br>
        <v-alert type="info" class="text-left" dense>
          There are no requirements at this time.
        </v-alert>
      </template>
    </div>
    
    
    
  
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue';
import requirementsMetadata from '@/scripts/requirements.json'
import floodrequirementsMetadata from '@/scripts/floodrequirements.json'
import bindv2requirementsMetadata from '@/scripts/bindv2requirements.json'
import { SbixService } from '@/api/SbixService'

export default {
  name: "Requirements",
  components: {
    DynamicFormControl
  },
  props: {
    value: {
        type: Object,
        default: () => {}
    },
    errors: {
        type: Array,
        default: () => []
    },
    disabled: Boolean,
    module: String,
    underwritingItemList: {
      type: Array,
      default: () => []
    },
    account: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'MANDATORY'
    },
    salesChannel: String,
    channel: String
  },
  emits: [
    'change',
    'requirements'
  ],
  data() {
    return {
      loaded: false,
      submission: null,
      requirements: [],
      requirementsValue: {}
        }
  },
  computed: {
    attestationRules() {
      if (this.mode == 'MANDATORY') {
        return 'required';
      }
      return null;
    },
    fileRules() {
      if (this.mode == 'MANDATORY') {
        return 'required|minDocuments:1';
      }
      return null;
    },
    findSalesChannel() {
      if (this.salesChannel) return this.salesChannel;
      const selectedQuote = this.$store.getters['additionalForm/getSelectedQuote'];
      const storeSalesChannel = selectedQuote !== undefined && selectedQuote.SalesChannel !== undefined ? selectedQuote.SalesChannel : (this.account !== undefined && this.account.SalesChannel !== undefined ? this.account.SalesChannel : null);
      return storeSalesChannel;
    },
    isV1OrV2() {
      if (!this.findSalesChannel) return false;
      const channels = ['Hiscox', 'Chubb', 'Vave', 'VavePersonalLines'];
      return channels.indexOf(this.findSalesChannel) > -1;
    }
  },
  methods: {
    itemChange(event, item) {
      //this.newValue[item] = event.value;

      if (item.Type == 'Attestation') {
        this.requirementsValue[item.Name] = {
          Type: 'Attestation',
          Text: item.Text,
          Value: event.value
        }
      } else if (item.Type == 'FileUpload') {
        this.requirementsValue[item.Name] = {
          Type: 'File',
          Text: item.Text,
          Files: event.value
        }
      }

      //Clean up any key/values that have object/bad keys, per bug reported by Jerome 2/22/2022
      Object.keys(this.requirementsValue).forEach(k => {
        if (typeof k !== 'string') {
          delete this.requirementsValue[k];
        }
      });

      this.$emit('change', this.requirementsValue);
    },
    formatAttestationText(text) {

      const USERNAME = this.$store.getters.getUserName;
      //const AGENTNAME = this.$store.getters.getControlValue('quoteForm', 'Agent');
      const INSPECTIONCONTACTPHONE = this.$store.getters.getInspectionContactPhone;
      
      let PROPERTYTYPE, INSUREDNAME, AGENTNAME = "";
      let product = "";

      if (this.account) {
        product = this.account.Product;
        INSUREDNAME = this.account.Insured.Name;
        
      } else if (this.submission) {
        
        product = this.$store.getters.getControlValue('additionalForm', 'CoverageOption');

        let entityName = this.$store.getters.getControlValue('additionalForm', 'EntityName') || "";
        let firstName = this.$store.getters.getControlValue('additionalForm', 'FirstName') || "";
        let lastName = this.$store.getters.getControlValue('additionalForm', 'LastName') || "";
        let agentName = this.$store.getters.getControlValue('additionalForm', 'Agent');

        INSUREDNAME = entityName ? entityName : firstName + ' ' + lastName;
        AGENTNAME = agentName;
      }

      switch (product) {
        case 'dp1':
        case 'dp3':
        case 'ho3':
        case 'ho5':
        case 'ho3_windonly': 
          PROPERTYTYPE = 'Home';
          break;
        case 'ho6':
        case 'ho6_windonly':
          PROPERTYTYPE = 'Condo';
          break;
        case 'ho4':
          PROPERTYTYPE = 'Rental';
          break;
        default:
          break;
      }

      if (!text) text = "";
      const result = text
        .split("%%USERNAME%%").join(USERNAME)
        .split("%%INSUREDNAME%%").join(INSUREDNAME)
        .split("%%PROPERTYTYPE%%").join(PROPERTYTYPE)
        .split("%%AGENTNAME%%").join(AGENTNAME)
        .split("%%INSPECTIONCONTACTPHONE%%").join(INSPECTIONCONTACTPHONE);

      return result;
    },
    async initialize() {

          let metadataToUse = null;
          this.requirements = [];
          let product = this.$store.getters.getControlValue('additionalForm', 'CoverageOption');

          if (this.channel === 'BindV2') {
            metadataToUse = bindv2requirementsMetadata
          } else if (this.channel === 'BindV1') {
            metadataToUse = floodrequirementsMetadata;
          } else {
            metadataToUse = requirementsMetadata;
          }
          let uil = [];
          if (this.module == 'additionalForm') {

            const s = this.$store.getters["additionalForm/getSubmission"];
            this.submission = {...s};

            if (!this.submission) {
              return;
            }

            //determine backing mode
            if (this.channel === 'BindV1' || this.channel === 'BindV2')
            {
              uil = [
                  {"UnderwritingCode":"Payment", "UnderwritingCodeStatus":0},
                  {"UnderwritingCode":this.submission.Data.Risk.value.State, "UnderwritingCodeStatus":0}
                ];
            }
            else
            {
              try {
                const selectedQuote = this.$store.getters["additionalForm/getSelectedQuote"];
                uil = await SbixService.underwritingItemList({policyKey: selectedQuote.id, head:false});
              } catch {
                console.error(this.salesChannel + ' Quote Not Found');
                return;
              }             
              
            }
            
          } else {
            uil = this.underwritingItemList;
          }
          
          const requiredTypeArray = [-2, -1, 0, 2, 3, 4];
          //const underwritingItemList = uil.filter(u => this.efileTypes.find(e => e.Key == u.UnderwritingCode)?.CanAdd && requiredTypeArray.indexOf(u.UnderwritingCodeStatus) > -1);
          const underwritingItemList = uil.filter(u => 
          metadataToUse.find(i => i.Key == u.UnderwritingCode)
            && requiredTypeArray.indexOf(u.UnderwritingCodeStatus) > -1
          );

          underwritingItemList.forEach(u => {
            //u['EFileType'] = this.efileTypes.find(e => e.Key == u.UnderwritingCode);
            const result = metadataToUse.find(i => i.Key == u.UnderwritingCode)
            u['Name'] = (result ? result.Name : "");
          })

          let foundRequirements = false

          underwritingItemList.forEach(u => {

              const metadata = metadataToUse.find(i => i.Key == u.UnderwritingCode);
              
              if (metadata && metadata.Type == 'Attestation') {
                this.requirements.push({
                  Type: "Attestation",
                  Name: u.UnderwritingCode,
                  Text: metadata ? this.formatAttestationText(metadata.Text) : null,
                  Required: true
                })
              } else {
                this.requirements.push({
                  Type: "FileUpload",
                  Name: u.UnderwritingCode,
                  Label: u.Name,
                  Text: metadata ? metadata.Text : null,
                  Required: true,
                  Template: metadata ? metadata.Template : null,
                  Max: metadata ? metadata.Max : 1,
                  Product: product,
                  IsV1OrV2: this.isV1OrV2
                })
              }
              
              foundRequirements = true;
          })
          this.$emit("requirements", foundRequirements);
    }
  },
  mounted() {
    setTimeout(() => {
      this.initialize();
      this.loaded = true;  
    }, 100)
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.requirementsValue = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    channel: {
      handler(newVal) {
        if (newVal) {
          this.initialize();
        }
      }
    }
  },
}

</script>

<style scoped>
.requirements div:last-child .v-card {
  margin-bottom: 0!important;
}
</style>